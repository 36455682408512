<template>
  <div class="caseM110-wrap">
    <div class="caseM110head">
      <header2></header2>
    </div>
    <div class="caseM110content">
      <div class="main-wrapA">
        <div class="content">
          <div class="regHd">
            <div class="back"><el-button class="back-btn" @click="back()" icon="el-icon-back" type="primary" size="mini" plain round>返回</el-button></div>
            个人信息
          </div>
          <div class="tabs">
            <div class="li-tab" @click="cur=0" :class="{active:cur==0}">
              <img  :src="picImgSrc.url" alt="">
            </div>
          </div>
          <div class="Revise" >
            <div class="reg-warp">
              <div class="personal">
                <div class="p-lf">个人信息</div>
                <div class="p-rg">
                  <el-form
                          ref="ruleForm"
                          :model="ruleForm"
                          status-icon
                          :rules="rules"
                          label-width="100px"
                  >
                    <el-form-item label="姓名" prop="stuName">
                      <el-input v-model="ruleForm.stuName" style="width: 450px"></el-input>
                      <span class="test">姓名作为实名制信息，不能修改，请认真输入</span>
                    </el-form-item>
                    <el-form-item label="昵称" prop="nickName">
                      <el-input v-model="ruleForm.nickName" style="width: 450px"></el-input>
                    </el-form-item>

                    <el-form-item label="头像" prop="">
                      <div class="portraitList" >
                        <img v-for="(item,index) in portraitList" :key="index" :class="{'onClickStyle':clickFlag == index}" @click="onClickItem(item,index)" :src="item.url" alt="" />
                      </div>
                    </el-form-item>
                    <el-form-item label="性别" prop="">
                      <el-select v-model="ruleForm.gender" placeholder="请选择" style="width: 450px">
                        <el-option
                                v-for="item in stateList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="生日" prop="">
                      <el-date-picker v-model="ruleForm.birthday" value-format=yyyy-MM-dd type="date"
                                      placeholder="请选择生日" style="width: 450px">
                      </el-date-picker>
                    </el-form-item>
                    <el-form-item label="地区" prop="area" >
                      <!--                      {{getStuByUserData.area}}-->
                      <el-cascader
                              style="width: 450px"
                              ref="getCitySelName"
                              v-model="selectedOptions2"
                              :options="findProviceCityData"
                              :props="defaultData"
                              @change="changefn"
                      ></el-cascader>
                    </el-form-item>
                    <el-form-item label="学校" prop="schoolName">
                      <el-input v-model="ruleForm.schoolName" style="width: 450px"></el-input>
                    </el-form-item>
                    <el-form-item label="入学年份" prop="">
                      <el-select v-model.trim="ruleForm.enrollmentYear" @change="GradeChang" clearable placeholder="年份选择">
                        <el-option
                                v-for="item in findGradeata"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <!--                    <el-form-item label="设置密码" prop="pass">-->
                    <!--                      <el-input v-model="ruleForm.pass" style="width: 450px"></el-input>-->
                    <!--                    </el-form-item>-->
                    <!--                    <el-form-item label="确认密码" prop="passWord">-->
                    <!--                      <el-input v-model="ruleForm.passWord" style="width: 450px"></el-input>-->
                    <!--                    </el-form-item>-->
                    <el-form-item label="邀请码" prop="">
                      <el-input v-model="ruleForm.invitationCode" style="width: 450px"></el-input>
                      <span class="test">请填写老师给予的邀请码，如果没有，则无需填写</span>

                    </el-form-item>
                  </el-form>

                </div>
              </div>

            </div>
            <div class="btn-warp">
              <el-button type="primary" @click="regForm('ruleForm')">提 交</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



</template>


<script>
  import header2 from '@/components/layout/header2.vue'
  import {queryCartListObj} from '@/api/cart'
  import { getStuByUserNameObj, stuRegObj,findGradeObj,findProviceCityObj,findScreenGradeObj,updatePersonObj } from '@/api/login'
  import {mapState} from "vuex";
  export default {
    name: '',
    inject: ['reload'],
    data() {
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.ruleForm.passWord !== '') {
            this.$refs.ruleForm.validateField('passWord');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        selectedOptions2:[],
        findProviceCityData:[],
        findScreenGradeData:[],
        defaultData:{   //联级选择
          value: 'value',
          label: 'label',
          children: 'children'
        },
        userInfo:{
          userName:''
        },
        findGradeata:[],
        profile:'',
        picImgSrc: {},
        disabled:false,
        time:5,
        btntxt:"发送验证码",
        clickFlag:-1,
        cur:0 ,//默认选中第一个tab
        stateList: [
          {
            name: '男',
            id: 1
          },
          {
            name: '女',
            id: 2
          }
        ],
        profilePhotoPathData:null,
        ruleForm:{
          stuName: "",
          nickName: "",
          profilePhotoPath: "",
          gender: null,
          proviceId:null,
          cityId:null,
          birthday: '',
          schoolName: '',
          className: '',
          gradeId: '',
          invitationCode: '',
          enrollmentYear:'',
        },
        portraitList: [
          {id:1, url: require('../../assets/images/m1.png')},
          {id:2, url: require('../../assets/images/m2.png')},
          {id:3, url: require('../../assets/images/m3.png')},
          {id:4, url: require('../../assets/images/m4.png')},
          {id:5, url: require('../../assets/images/m5.png')},
        ],
        value: [],

        rules: {
          stuName: [
            {
              required: true,
              message: '请输入用户名',
              trigger: 'blur',
            },
            {
              min: 1,
              max: 30,
              message: '用户名长度必须在 1 到 30个字符',
              trigger: 'blur',
            },
          ],
          nickName:[
            {
              required: true,
              message: '请输入昵称',
              trigger: 'blur',
            },
          ],

          pass: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' },
            { validator: validatePass, trigger: 'blur' }
          ],
          passWord:[
            { required: true, message: '请确认密码', trigger: 'blur' },
            { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' },
            { validator: validatePass2, trigger: 'blur', required: true }
          ],
          mobileNumber: [
            {
              required: true,
              message: '手机号码不能为空',
              trigger: 'blur',
            },
            {pattern: /^1[345678]\d{9}$/, message: '请输入正确是手机号'},
            {
              min: 1,
              max: 30,
              message: '用户名长度必须在 1 到 30个字符',
              trigger: 'blur',
            },
          ],


          code: [
            {
              required: true,
              message: '请输入图形验证码',
              trigger: 'blur',
            },
            {
              min: 4,
              max: 4,
              message: '验证码长度必须为4个字符',
              trigger: 'blur',
            },
          ],
        },
      }
    },



    mounted() {
      // 从登陆页面过来的才调用获取购物车方法
      let params = this.$route.stu;
      // params 为true且值为login
      if (this.stu == 1) {
        this.queryCartList();
      }
    },
    methods: {
      //返回
      back() {
        this.$router.go(-1);
      },
      ProviceCityChange() {
        this.vals=findProviceCityObj(this.selectedOptions, this.findProviceCityData);
        this.vals.map(item=>{
          this.name = item.name;
          this.id = item.id
          console.log(this.id,'9900');
          console.log(this.name,'9911');
        })

      },
      changefn(value) {
        this.ruleForm.area=JSON.stringify(value)

      },
      // 查询学生信息
      getStuByUser() {
        getStuByUserNameObj().then((res) => { // eslint-disable-line no-unused-vars
          if (res.code == 200) {
            this.$store.commit('saveStuByUserRes', res.data)
            localStorage.setItem("userInfo", JSON.stringify(res.data))
            this.profilePhotoPathData = res.data.profilePhotoPath
            console.log(res.data.profilePhotoPath,'900111')
            this.ruleForm = res.data
            console.log(this.ruleForm,'90000')
            this.ruleForm.profilePhotoPath = res.data.profilePhotoPath
            this.clickFlag = Number(res.data.profilePhotoPath)-1
            console.log(res.data.area, '---')
            this.ruleForm.area1= res.data.area ? JSON.parse(res.data.area).map(it => Number(it)) : ''
            this.selectedOptions2 = this.ruleForm.area1
            let str= res.data.area || ''
            //this.selectedOptions=JSON.stringify(str)
            //this.selectedOptions=str.split(":")
            this.selectedOptions=str.split(' ')
            console.log(this.selectedOptions,'111')
            this.ruleForm = this.StuByUserRes;
            this.portraitList.forEach((ele) => {
              console.log(this.profile , ele.id, '--------------------')
              if (this.ruleForm.profilePhotoPath == ele.id){
                this.picImgSrc = ele
              }
            })
          }
        })

      },
      findProviceCity() {

        findProviceCityObj().then((res) => { // eslint-disable-line no-unused-vars
          if (res.code == 200) {
            this.findProviceCityData = res.data
            console.log(res)
          }
        })

      },
      findGrade() {

        findGradeObj().then((res) => { // eslint-disable-line no-unused-vars
          if (res.code == 200) {
            this.findGradeata = res.data
            console.log(res)
          }
        })

      },
      GradeChang($event){
        this.ruleForm.enrollmentYear = $event;
      },
      findScreenGrade() {

        findScreenGradeObj().then((res) => { // eslint-disable-line no-unused-vars
          if (res.code == 200) {
            this.findScreenGradeData = res.data
            console.log(res)
          }
        })

      },
      // 查询购物车列表
      queryCartList() {
        let params = {}
        queryCartListObj(params).then((res) => { // eslint-disable-line no-unused-vars
          this.CartListData = res.data
          console.log(res.data.length, '购物车的数量')
          this.$store.commit('saveCartCount', res.data.length)
        })
      },
      xbChange($event) {
        this.ruleForm.gender=$event
      },
      //点击改头像
      onClickItem(item,index){
        this.clickFlag = index;
        this.ruleForm.profilePhotoPath = item.id;

        this.portraitList.forEach((ele) => {
          this.profileId= ele.id
          this.ruleForm.profilePhotoPath=this.StuByUserRes.profilePhotoPath
          if (this.ruleForm.profilePhotoPath == ele.id){
            this.picImgSrc = ele
          }
        })
        console.log(item.id,'获取头像ID')
      },
      // 请求获取短信接口
      sendcode(){
        this.time=30;
        this.timer();
      },
      //发送手机验证码倒计时
      timer() {
        if (this.time > 0) {
          this.disabled=true;
          this.time--;
          this.btntxt="倒计时"+this.time+"秒";
          setTimeout(this.timer, 1000);
        } else{
          this.time=0;
          this.btntxt="发送验证码";
          this.disabled=false;
        }
      },


      regForm(formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            let params = {
              stuName:this.ruleForm.stuName,
              nickName:this.ruleForm.nickName,
              id:this.ruleForm.id,
              profilePhotoPath:this.ruleForm.profilePhotoPath,
              area:this.ruleForm.area,
              gender:this.ruleForm.gradeId,
              birthday:this.ruleForm.birthday,
              schoolName:this.ruleForm.schoolName,
              enrollmentYear:this.ruleForm.enrollmentYear,
              className:null,

              invitationCode:this.ruleForm.invitationCode,

              // nickName:this.ruleForm.nickName,
              // profilePhotoPath:this.ruleForm.profilePhotoPath,
              // gender:this.ruleForm.gender,
              // birthday:this.ruleForm.birthday,

            }
            updatePersonObj(params).then((res) => { // eslint-disable-line no-unused-vars
              if(res.code == 200) {
                this.reload()
                this.getStuByUser()

                // this.$router.push({
                //   path: "/",
                // });
                this.$message({
                  message: res.message,
                  type: 'success'
                });
              }else  {
                this.$message({
                  message: res.message,
                  type: 'warning'
                });
                console.log(res.code)
              }
            })
          }
        })

      },




    },
    created() {
      this.getStuByUser()


      this.findProviceCity()
      this.findScreenGrade()
      this.findGrade()


    },
    computed:{
      ...mapState([
        'StuByUserRes',
      ]),
    },
    components: {
      // 图标
      header2

      //注册图标组件名称
    }
  }
</script>
<style lang="scss" scoped>
  .back-btn {
    border: 1px solid #c4e7ff;padding: 0px 10px; height:32px;line-height:32px; background-color: #37AEFF; color:#c4e7ff
  }
  .caseM110-wrap{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    overflow-y: hidden;
    .caseM110head{
      width: 100%;
      height: 70px;
      position: fixed;
      z-index: 0;
    }
    .caseM110content{
      width: 100%;
      position: fixed;
      /*固定定位*/
      top: 70px;
      bottom: 0;
      overflow-y: auto;
    }
    .main-wrapA {
      padding-top: 20px;
      .content {
        width: 98%;
        height: 820px;
        box-sizing: border-box;
        .regHd {
          width: 100%;
          height: 50px;
          line-height: 50px;
          font-size: 15px;
          font-weight: bold;
          text-align: center;
          border-bottom: 1px solid #238bff;
          position: relative;
          .back {
            position: absolute;
            justify-content: flex-start;
            left: 0px;
          }
        }
      }
    }
  }

  .tabs {
    width: 100%;
    height: 90px;
    font-size: 15px;
    font-weight: bold;
    display:flex;
    justify-content: center;
    align-items: center;
    .active {
      border-radius: 50px;
      border: 1px solid #F77134;
      background: #FFFCB2;
      color: #ff6820;
    }

    .li-tab {
      width: 60px;
      height: 60px;
      border-radius: 50px;
      border: 1px solid #bbb;
      margin: 0px 25px 0 25px;
      display: inline-block;
      text-align: center;
      font-size: 14px;
      cursor: pointer;

      img {
        width: 60px;
        height: 60px;
        border-radius: 50px;
      }

      .t1 {
        text-align: center;
      }
    }


  }
  .Revise {
    display: block;
    width: 100%;
    height: 80%;
    margin-top: 10px;

    .reg-warp {
      width: 90%;
      margin: auto;
      padding: 0 50px;
      box-sizing: border-box;
      overflow-y: auto;
      .test {
        margin-left: 30px;
        color: #b1bcd6;
      }
      .personal {
        width: 100%;
        display: flex;
        margin-top: 20px;
        .p-lf {
          height: 500px;
          display: flex;
          padding-right: 30px;
          align-items: center;
          text-align: center;
          border-right: 1px dashed #d5dee9;

          width: 20px;
          line-height: 24px;
          font-size: 16px;
          font-weight: bold;
        }
        .p-rg {
          width: 1200px;
          height: 500px;
          .portraitList {
            width: 100%;
            img {
              width: 50px;
              height: 50px;
              margin-right: 20px;
              float: left;

            }
          }
          .onClickStyle {
            width: 50px;
            height: 50px;
            background-color: #238bff;
            border: 2px solid #238bff;
            border-radius: 50px;
            img {

            }
          }
          //background-color: #238bff;
        }
      }
      .account {
        width: 100%;
        display: flex;
        margin-top: 40px;
        .p-lf {
          height: 320px;
          display: flex;
          padding-right: 30px;
          align-items: center;
          text-align: center;
          border-right: 1px dashed #d5dee9;

          width: 20px;
          line-height: 24px;
          font-size: 16px;
          font-weight: bold;
        }
        .p-rg {
          width: 1200px;
          height: 320px;
          .portraitList {
            width: 100%;
            img {
              width: 50px;
              height: 50px;
              margin-right: 20px;
              float: left;
            }
          }
          //background-color: #238bff;
        }
      }

      .parentpersonal {
        width: 100%;
        display: flex;
        margin-top: 20px;
        .p-lf {
          height: 190px;
          display: flex;
          padding-right: 30px;
          align-items: center;
          text-align: center;
          border-right: 1px dashed #d5dee9;

          width: 20px;
          line-height: 24px;
          font-size: 16px;
          font-weight: bold;
        }
        .p-rg {
          width: 1200px;
          height: 190px;
          .portraitList {
            width: 100%;
            img {
              width: 50px;
              height: 50px;
              margin-right: 20px;
              float: left;
            }
          }
          //background-color: #238bff;
        }
      }
      .parentaccount {
        width: 100%;
        display: flex;
        margin-top: 40px;
        .p-lf {
          height: 230px;
          display: flex;
          padding-right: 30px;
          align-items: center;
          text-align: center;
          border-right: 1px dashed #d5dee9;

          width: 20px;
          line-height: 24px;
          font-size: 16px;
          font-weight: bold;
        }
        .p-rg {
          width: 1200px;
          height: 230px;
          .tip {
            width: 96%;
            height: 40px;
            line-height: 40px;
            padding: 0 10px;
            margin: auto auto 10px auto;
            background: #FFECD8;
            border: 1px solid #FF9B36;
            opacity: 1;
            color: #D50007;
          }
          .portraitList {
            width: 100%;
            img {
              width: 50px;
              height: 50px;
              margin-right: 20px;
              float: left;
            }
          }
          //background-color: #238bff;
        }
      }
    }
    .btn-warp {
      width: 100%;
      height: 40px;
      margin-top: 20px;
      padding-bottom: 20px;
      text-align: center;
    }
  }
</style>
